<template>
  <div>
    <!-- ALL ITEMS CARD -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" offset-md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search By Item ID, Type, Name"
                v-model="keyword"
                type="text"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- ALl Item table -->
      <b-table
        id="NewProductListTableId"
        ref="refNewProductListTable"
        :items="allItems"
        responsive
        :fields="tableColumns"
        primary-key="prod_id"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        :keyword="keyword"
      >
        <template #cell(index)="data">
          <span class="text-black">
            {{
              parseInt((pagination.currentPage - 1) * pagination.perPage) +
              data.index +
              1
            }}
          </span>
        </template>

        <template #cell(id)="data" class="text-black">
          <b-link
            :to="{
              name: `product-details`,
              query: { id: data.item.prod_id },
            }"
            class="font-weight-bold"
            @click="
              $router.push({
                name: `product-details`,
                query: { id: data.item.prod_id },
              })
            "
          >
            {{ data.item.prod_auto_id }}
          </b-link>
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{
                data.item.creator.user_fname +
                " " +
                data.item.creator.user_lname
              }}
            </span>
          </b-media>
        </template>

        <template #cell(email)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.creator.user_email }}
            </span>
          </b-media>
        </template>

        <template #cell(details)="data">
          <div class="d-flex align-items-center" :key="data">
            <span>
              {{
                data.item.creator.user_country == ""
                  ? "Country Pending"
                  : data.item.creator.user_is_stripe_pending == 1
                  ? "Stripe Pending"
                  : "Stripe Completed"
              }}
            </span>
            <a
              v-b-modal.payout-details-sm
              @click="showPayoutDetails(data.item.creator)"
            >
              <b-avatar class="mr-1" variant="light-success">
                <feather-icon size="18" icon="EyeIcon" />
              </b-avatar>
            </a>
          </div>
        </template>

        <template #cell(amount)="data">
          <div v-if="data.item.prod_earning_price != null">
            {{
              setAmount(
                data.item.prod_amount_currency,
                data.item.prod_listing_price,
                "listing"
              )
            }}
            {{
              setAmount(
                data.item.prod_amount_currency,
                data.item.prod_earning_price,
                "earning"
              )
            }}
          </div>
          <div v-else>
            {{
              setAmount(
                data.item.prod_amount_currency,
                data.item.prod_earning_price,
                "earning"
              )
            }}
          </div>
        </template>

        <template #cell(sold_date)="data">
          <div>
            {{
              data.item.prod_sold_datetime
                ? getHumanDate(data.item.prod_sold_datetime)
                : "N/A"
            }}
          </div>
        </template>

        <template #cell(status)="data">
          <div>
            {{ paymentStatus(data.item.prod_status) }}
          </div>
        </template>
      </b-table>

      <b-pagination
        class="pl-2"
        aria-controls="NewProductListTableId"
        v-model="pagination.currentPage"
        :total-rows="allItems.length"
        :per-page="pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>

    <b-modal
      id="payout-details-sm"
      cancel-variant="secondary"
      ok-only
      ok-title="Close"
      centered
      size="sm"
      title="User's Quick Payout Details"
    >
      <b-card-text>
        <p><b>Name:</b> {{ payoutDetails.name }}</p>
        <p><b>Email:</b> {{ payoutDetails.email }}</p>
        <p><b>Payout Method:</b> {{ payoutDetails.method }}</p>
        <p><b>Pay Account ID:</b> {{ payoutDetails.account_id }}</p>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import feather from "feather-icons";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import storageService from "@/apiServices/storageService";
import ProductServices from "@/apiServices/ProductServices";

import { refurbishStatus } from "../../SellerItems/refurbishStatusCode";
import { sellStatus } from "../../SellerItems/sellStatusCode";

import { avatarText } from "@core/utils/filter";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
    feather,
    FeatherIcon,
    moment,
  },
  setup() {},
  beforeMount() {
    this.loadAllItems();
  },
  directives: {
    Ripple,
  },
  computed: {
    allItems() {
      return this.keyword
        ? this.allItemsData.filter((item) => {
            if (item.prod_designer || item.prod_auto_id) {
              if (
                item.prod_designer
                  .toString()
                  .toLowerCase()
                  .includes(this.keyword.toString().toLowerCase()) ||
                item.prod_auto_id
                  .toString()
                  .toLowerCase()
                  .includes(this.keyword.toString().toLowerCase()) ||
                item.prod_sub_category
                  .toString()
                  .toLowerCase()
                  .includes(this.keyword.toString().toLowerCase())
              ) {
                console.log("Searching => ", item);
                return true;
              } else {
                console.log("Saerch False =>", item);
                return false;
              }
            }
          })
        : this.allItemsData;
    },
  },
  data() {
    return {
      sellStatus: sellStatus,
      refurbishStatus: refurbishStatus,
      sellStatusObj: {},
      refurbishStatusObj: {},

      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      allItemsData: [],
      keyword: "",
      pagination: {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [10, 20, 30],
      },

      //User item ID, Seller Name, Seller Email, Pyout Details modal, sold date, payout amount, payout status

      tableColumns: [
        "index",
        { key: "id", label: "Item ID", sortable: true },
        { key: "name", label: "Seller Name", sortable: true },
        { key: "email", label: "Seller Email", sortable: true },
        { key: "details", label: "Pyout Details", sortable: true },
        { key: "sold_date", label: "Sold Date", sortable: true },
        { key: "amount", label: "Payout Amount", sortable: true },
        { key: "status", label: "Payout Status" }, //(Pending, Intitated, Compelted)
      ],
      payoutDetails: {
        name: null,
        email: null,
        country: null,
        method: null,
        account_id: null,
      },
    };
  },
  methods: {
    showPayoutDetails(userData) {
      console.log(userData);
      this.payoutDetails.name = userData.user_fname + " " + userData.user_lname;
      this.payoutDetails.email = userData.user_email;
      if (userData.user_country == "") {
        this.payoutDetails.method = "Country Pending...";
        this.payoutDetails.account_id = "N/A";
      } else if (userData.user_country == "+91") {
        this.payoutDetails.method = "PayU";
        this.payoutDetails.account_id =
          userData.user_is_payu_pending == 0
            ? userData.user_payu_details
            : "Pending";
      } else {
        this.payoutDetails.method = "Stripe";
        this.payoutDetails.account_id =
          userData.user_is_stripe_pending == 0
            ? userData.user_stripe_id
            : "Pending";
      }
    },

    loadAllItems() {
      ProductServices.getSoldProducts()
        .then((response) => {
          this.allItemsData = response.data.data;
          console.log("All_Sold_Item: ", this.allItemsData);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    setAmount(currency, price, type) {
      if (price != null) {
        if (type == "earning") {
          return "Payout: " + currency + price;
        }

        if (type == "listing") {
          return "Listing: " + currency + price;
        }
      } else {
        return "Earning price not available";
      }
    },

    paymentStatus(status) {
      let message;
      if (status == "15") {
        message = "Pending";
      } else if (status == "16") {
        message = "Initiated";
      } else if (status == "17") {
        message = "Completed";
      } else {
        message = "N/A";
      }
      return message;
    },

    titleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    deactivate(prod_id, type) {
      console.log(prod_id);

      const data = {
        prod_id: prod_id,
        type: type,
      };

      ProductServices.updateActiveInactive(data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "BoxIcon",
              variant: "success",
            },
          });

          this.loadAllItems();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    newStatus(active, draft, status, type) {
      if (active === true) {
        if (draft == "save") {
          if (type == "sell") {
            let obj = {};
            const x = this.sellStatus.filter((o, index) => {
              if (o.status_code === parseInt(status))
                obj = this.sellStatus[index];
            });
            return obj.status_message;
          } else if (type == "refurbish") {
            let obj = {};
            const x = this.refurbishStatus.filter((o, index) => {
              if (o.status_code === parseInt(status))
                obj = this.refurbishStatus[index];
            });
            return obj.status_message;
          } else {
            return "Under Review";
          }
        } else {
          return "Saved As Draft";
        }
      } else {
        return "Deactivated";
      }
    },
  },
};
</script>

<style>
.table {
  color: #444 !important;
}

[dir] .table th,
[dir] .table td {
  padding: 1.5rem;
}

[dir] .card {
  border-radius: 1rem !important;
}

/* [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th{
  background-color: white !important;
} */

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  background-color: #ffffff00 !important;
}

[dir] .table thead th {
  border-bottom: 0px !important;
  /* text-shadow : 2px 1px 1px 2px #000000; */
}

th > div {
  /* text-shadow : 2px 1px 1px 2px #000000 !important;  */
  color: black !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.swal2-html-container {
  color: white;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
